import React, { useState, useEffect, createContext, Fragment } from "react";
import Pasqua2023 from "../asset/Pasqua2023.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FerragostoMare from "../asset/MenuFerragostoMare.jpeg";
import FerragostoMontagna from "../asset/MenuFerragostoTerra.jpeg";
import MenuStorico2 from "../asset/MenuStorico2.jpg";

import Natale2024 from "../asset/Natale2024.jpeg";
import Capodanno2025 from "../asset/Capodanno2025.jpg";
import SV2025 from "../asset/SanValentino2025.jpeg";
import SP2024 from "../asset/Pasqua2024.jpeg";

function FestivitaRassegne() {
  return (
    <>
      <h1 className="p-4">Festività e Rassegne</h1>
      <>
        <h3>
          <i> Romantica cena di San Valentino </i>
        </h3>
        I fratelli Canova sono lieti di proporre una romantica serata
        <br />
        per festeggiare insieme la serata degli innamorati con un raffinato menù
        degustazione di coppia
        <br />
        <br />
      </>
      <>
        <>
          <img src={SV2025} className="img-thumbnail" alt="Responsive image" />
          <br />
          <br />
          <br />
        </>
      </>
      {/* <h3>
          <i> Cenone con Ballo di Capodanno </i>
        </h3>
        I fratelli Canova sono lieti di proporre una serata unica per
        festeggiare insieme l'anno nuovo
        <br />
        Un ricco menù degustazione accompagna gli ospiti alla mezzanotte e a una
        divertente serata danzante
        <br />
        <br />
        <img
          src={Capodanno2025}
          className="img-thumbnail"
          alt="Responsive image"
        />
        <br />
        <br />
        <br />
      </> 
      <>
        <h3>
          <i> Pranzo di Natale </i>
        </h3>
        <p>
          I fratelli Canova sono lieti di proporre l'offerta e il menù
          <br />
          dedicato al pranzo del Santo Natale 2024 in stile Ristorante
          Atlantide:
          <br />
          un'atmosfera familiare e accogliente accompagna un raffinato menù
          degustazione
          <br />
        </p>
      </>
      <img src={Natale2024} className="img-thumbnail" alt="Responsive image" />
      <br />
      <br />
      <br />

     <>
        <h3>
          <i> Santa Pasqua 2024</i>
        </h3>
        I fratelli Canova sono lieti di proporre un raffinato menù degustazione
        <br />
        per festeggiare insieme la Santa Pasqua 2024
        <br />
        <br />
      </>
      <>
        <img src={SP2024} className="img-thumbnail" alt="Responsive image" />
        <br />
        <br />
        <br />
      </>
      <>
        <h3>
          <i> Romantica cena di San Valentino </i>
        </h3>
        I fratelli Canova sono lieti di proporre una romantica serata
        <br />
        per festeggiare insieme la serata degli innamorati con un raffinato menù
        degustazione di coppia
        <br />
        <br />
      </>
      
      <>
        <img src={SV2024} className="img-thumbnail" alt="Responsive image" />
        <br />
        <br />
        <br />
      </> */}

      <>
        <br />
        <br />
        <br />
      </>

      {/* <h3>
        <i> Malnate tra Storia e Sapori </i>
      </h3>
      Ristorante Atlantide è lieto di ospitare l'evento
      <br />
      "Malnate tra Storia e Sapori"
      <br />
      Una serata dedicata alle nostre proposte di cucina locale
      <br />
      e alla lectio del Dott. A. Sassi dell'Associazione Amici Ricerche Storiche
      <br />
      <img
        src={MenuStorico2}
        className="img-thumbnail"
        alt="Responsive image"
      />
      <br />
      <br /> */}
    </>
  );
}
export default FestivitaRassegne;

// {
//   /*

//         <i>
//           <b> Pranzi e Cene Natalizie </b>
//         </i>
//         <br />
//         <a href="/#/PranziCene">Clicca qui per scoprire</a>
//         <br />
//         i nuovi menù di terra e mare dedicati ai pranzi e cene natalizie del
//         2022!
//         <br />
//         <br />
//         <i>
//           <b>Cenone con Ballo diCapodanno</b>
//         </i>
//         <br />
//         I fratelli Canova sono lieti di proporre una serata unica per festeggiare insieme l'anno nuovo
//         <br />
//         Un ricco menù degustazione accompagna gli ospiti alla mezzanotte e a una divertente serata danzante
//         <br />
//         <br />
//       </>
//       <>
//       <img src={Capodanno2022} className="img-thumbnail" alt="Responsive image" />
//       <br />
//       <br />
//       </>
//       <>
//         <i>
//           <b>Pranzo di Natale 2022</b>
//         </i>
//         <br />
//         I fratelli Canova sono lieti di proporre l'offerta e il menù
//         <br />
//         dedicato al pranzo del Santo Natale 2022 in stile Ristorante Atlantide:
//         <br />
//         un'atmosfera familiare e accogliente accompagna un raffinato menù
//         degustazione
//         <br />
//       </>
//       <br />
//       <img src={Natale2022} className="img-thumbnail" alt="Responsive image" />
//       <br />
//       <br />
//       <br />

//       <i>
//       <b> Pranzo degustazione di Ferragosto </b>
//       </i>
//       <br />
//       Ristorante Atlantide propone un raffinato menù degustazione
//       <br />
//       per festeggiare insieme il Ferragosto
//       {in un caldo ambiente familiare le festività
//       Pasquali}
//       <br />
//       <br />
//       <Row>
//         <Col xs={12} md={6}>
//           <img
//             src={FerragostoMare}
//             className="img-thumbnail"
//             alt="Responsive image"
//           />
//         </Col>
//         <Col xs={12} md={6}>
//           <img
//             src={FerragostoMontagna}
//             className="img-thumbnail"
//             alt="Responsive image"
//           />
//         </Col>
//       </Row>
//       <br />
//       <br />
//     </>
//   );

// */
// }
